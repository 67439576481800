<template>
  <router-link :to="singleAssignmentRoute"
               class="assignment-invitation__wrapper">
    <article class="assignment-invitation is-progress-bar"
             :class="{'in-progress': progressActive}">
      <p class="assignment-invitation__job-id">#{{ jobId }}</p>
      <!----  Job Info ---->
      <div class="assignment-invitation__job-info-wrapper">
        <div class="assignment-invitation__job-info-first-section">
          <p class="assignment-invitation__title">{{ invitationTitle }}</p>
          <div class="assignment-invitation__time-blocks time-blocks">
            <div v-if="!isProactiveJob"
                 class="time-blocks__item invitation-time">
              <span>{{ invitationTimeText }}</span>
              <time>{{ invitationTime }}</time>
            </div>
            <div class="time-blocks__item duration-time">
              <span>{{ durationTimeText }}</span>
              <time>{{ duration }}</time>
            </div>
          </div>
        </div>
        <div class="assignment-invitation__job-info-second-section">
          <p class="assignment-invitation__session-type"
             :class="[`assignment-invitation__session-type--${sessionType}`]">{{ sessionTypeValue }}</p>
          <sk-chip :label="status.text"
                   :color="status.class" />
        </div>
      </div>
      <!----  Booker Info ---->
      <div class="assignment-invitation__booker-info">
        <p class="assignment-invitation__enterprise">{{ enterpriseName }}</p>
        <p class="assignment-invitation__department">{{ departmentName }}</p>
      </div>
      <div v-if="isMobileView"
           class="assignment-invitation__pricing">
        <p class="assignment-invitation__job-fee shimmer"
           :class="{'shimmer-on': jobFeeProgressActive}">{{ jobFee }}</p>
        <p class="assignment-invitation__breakdown-link"
           v-html="breakdownLink"></p>
      </div>
      <!----  Day Timeline ---->
      <assignment-timeline :assignment="assignment" />
      <!----  Invitation Footer ---->
      <div class="assignment-invitation__footer">
        <div v-if="!isMobileView"
             class="assignment-invitation__pricing">
          <p class="assignment-invitation__job-fee shimmer"
             :class="{'shimmer-on': jobFeeProgressActive}">{{ jobFee }}</p>
          <p class="assignment-invitation__breakdown-link"
             v-html="breakdownLink"></p>
        </div>
        <div class="assignment-invitation__actions">
          <button class="sk-btn sk-btn--white assignment-invitation__actions--decline"
                  @click.prevent.stop="declineInvitation">{{ declineBtnText }}</button>
          <button class="sk-btn sk-btn--default assignment-invitation__actions--apply"
                  :class="[sessionType]"
                  @click.prevent.stop="applyInvitation">{{ applyBtnText }}</button>
        </div>
      </div>
    </article>

  </router-link>
</template>

<script>
  import AssignmentTimeline from '@/components/assignment_components/invitation/AssignmentTimeline';
  import helpers from '@/helpers';
  import ApplyDeclineMixin from '@/mixins/ApplyDeclineMixin';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      'assignment-timeline': AssignmentTimeline
    },
    mixins: [ApplyDeclineMixin],
    props: {
      assignment: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        progressActive: false,
        jobFeeProgressActive: false,
        amount: '',
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      jobId() { return this.assignment.id; },
      sessionType() { return this.assignment.sessionType; },
      isInPersonJob() { return this.sessionType === 'in_person'; },
      sessionTypeValue() {
        switch (this.sessionType) {
          case 'in_person':
            return helpers.getFullAddress(this.assignment.address);
          case 'video':
            return this.assignment.videoPlatformName;
          default:
            return helpers.getJobInfo.jobType(this.sessionType, this);
        }
      },
      isInvitedJob() { return this.assignment.status === 'invited'; },
      isProactiveJob() { return this.assignment.status === 'proactive'; },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      langTo() { return this.$getLangName(this.assignment.langToId); },
      category() { return this.assignment.category; },
      subject() { return this.assignment.subject; },
      invitationTitle() {
        const title = this.subject || this.category;
        const subjectCategoryCombo = this.subject && this.category ? `${this.category}: ${this.subject}` : title;
        return `${subjectCategoryCombo} ${this.langTo}`;
      },
      invitationTime() {
        return this.$moment(this.assignment.invitationTime).format('DD.MM.YYYY, H:mm');
      },
      duration() {
        const statTime = this.assignment.startTime;
        const finishTime = this.assignment.finishTime;
        const timeDuration = `${this.$moment(statTime).format('HH:mm')} - ${this.$moment(finishTime).format('HH:mm')}`;
        const startDate = this.$moment(statTime).format('DD.MM.YYYY');
        return `${timeDuration}, ${startDate}`;
      },
      enterpriseName() { return this.assignment.enterpriseName; },
      departmentName() { return this.assignment.departmentName; },
      jobFee() { return this.amount ? `${this.amount} NOK` : '___ NOK'; },
      declineBtnText() { return this.isMobileView ? this.$gettext('Decline') : ''; },
      applyBtnText() {
        if (this.isMobileView) {
          return (this.sessionType === 'in_person') ? this.$gettext('Travel Options') : this.$gettext('Apply');
        } else return '';
      },
      singleAssignmentRoute() { return this.$makeRoute({name: 'ServerOneAssignment', params: {id: this.jobId}}); },
      breakdownLink() {
        const jobPageLink = this.$router.resolve(this.singleAssignmentRoute).href;
        const temp = this.isInPersonJob
          ? this.$gettext('See details for travel costs. <a class="sk-link" href="%{link}">See breakdown</a>')
          : this.$gettext('<a class="sk-link" href="%{link}">See breakdown</a>');
        return this.$gettextInterpolate(temp, {link: jobPageLink});
      },
      status() { return helpers.getJobInfo.jobStatusObjChip(this, this.assignment.status); },
      invitationTimeText() { return this.$gettext('Invitation date'); },
      durationTimeText() { return this.$gettext('Start-end date'); },
    },
    watch: {
      assignment() {
        this.getJobTotalInvoice();
      }
    },
    methods: {
      startProgress() { this.progressActive = true; },
      stopProgress() { this.progressActive = false; },
      getJobTotalInvoice() {
        const payload = {
          jobId: this.jobId,
          params: {
            interpreter_uid: this.userUid
          }
        };
        this.jobFeeProgressActive = true;
        this.$store.dispatch('s_DashboardStore/getInterpreterTotalInvoicePreview', payload).then((res) => {
          this.amount = res?.invoice?.amount;
          this.jobFeeProgressActive = false;
        }).catch(() => {
          this.jobFeeProgressActive = false;
        });
      }
    },
    mounted() {
      this.getJobTotalInvoice();
    }
  };
</script>
<style scoped>
@keyframes shimmer {
  100% { -webkit-mask-position: left; }
}

.assignment-invitation__wrapper {
  width: 100%;
}

.assignment-invitation__wrapper:hover {
  background-color: var(--salita-50);
}

.assignment-invitation {
  padding: 24px 20px;
}

.assignment-invitation__job-id {
  color: var(--color-primary);
}

.assignment-invitation__job-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.assignment-invitation__job-info-first-section {
  line-height: 24px;
}

.assignment-invitation__title,
.assignment-invitation__job-fee {
  font-weight: 600;
  font-size: 16px;
}

.assignment-invitation__time-blocks {
  display: flex;
  margin-top: 10px;
}

.time-blocks__item {
  display: flex;
  flex-direction: column;
}

.time-blocks__item span {
  color: #666;
  font-size: 10px;
  line-height: 12px;
}

.invitation-time {
  margin-right: 24px;
}

.assignment-invitation__session-type {
  margin-bottom: 10px;
  padding: 6px 16px 6px 30px;
  border-radius: 4px;
  background-color: var(--cool-gray-50);
  background-position: 10px center;
  background-repeat: no-repeat;
}

.assignment-invitation__session-type--phone {
  background-image: url(~@assets/imgs/assignment_types/phone_icon_black.svg);
}

.assignment-invitation__session-type--video {
  background-image: url(~@assets/imgs/assignment_types/video_icon_black.svg);
}

.assignment-invitation__session-type--in_person {
  background-image: url(~@assets/imgs/assignment_types/location_icon_black.svg);
}

.assignment-invitation__job-info-second-section {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 20px;
}

.assignment-invitation__booker-info {
  padding: 8px 0;
}

.assignment-invitation__pricing {
  margin-top: 5px;
  line-height: 24px;
}

.assignment-invitation__department {
  color: var(--black-500);
}

.assignment-invitation__footer {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
}

.assignment-invitation__breakdown-link {
  font-size: 12px;
}

.assignment-invitation__actions {
  display: flex;
  align-items: center;
}

.assignment-invitation__actions > button:not(:last-child) {
  margin-right: 8px;
}

.assignment-invitation__actions > button {
  width: auto;
}

.assignment-invitation__actions--decline,
.assignment-invitation__actions--apply {
  padding-right: 15px;
  padding-left: 35px;
  background-position: center;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.assignment-invitation__actions--decline {
  background-image: url(~@assets/imgs/invitations/close_icon_orange.svg);
}

.assignment-invitation__actions--apply {
  background-image: url(~@assets/imgs/invitations/tick_icon_white.svg);
}

.assignment-invitation__actions--apply.in_person {
  background-image: url(~@assets/imgs/invitations/arrow_right_white.svg);
}

.shimmer {
  display: inline-block;
  background-repeat: no-repeat;
  transition: all 0.4s ease-in;
  animation: none;
}

.shimmer.shimmer-on {
  display: inline-block;
  color: grey;
  animation: shimmer 2s infinite;
  -webkit-mask: linear-gradient(-60deg, #000 20%, #0005, #000 70%) right/300% 100%;
}

@media (max-width: 767px) {
  .assignment-invitation {
    width: 100%;
    padding: 24px 16px;
  }

  .assignment-invitation__job-info-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .assignment-invitation__job-info-second-section {
    align-items: flex-start;
    margin-top: 10px;
  }

  .assignment-invitation__footer {
    flex-direction: column;
  }

  .assignment-invitation__actions > button {
    width: 100%;
    background-position: 25% 50%;
  }

  .assignment-invitation__actions--apply.in_person {
    padding-right: 35px;
    padding-left: 15px;
    background-position: 90% 50%;
  }
}
</style>

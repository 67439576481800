<template>
  <section class="assignment-timeline__wrapper">
    <div @click.prevent.stop="toggleAssignmentPreview">
      <availability-timeline :events-list="timelineEvents"
                             :selected-event="selectedEventData"
                             :date-range-from="assignmentStartTime"
                             :date-range-to="assignmentFinishTime"
                             :col-width="38"
                             :timeline-step="60"
                             class="assignment-timeline pointer" />
    </div>
    <!---- Next and Previous Jobs to current Assignment   ---->
    <div v-show="isAssignmentPreviewOpened"
         class="assignment-timeline__preview-wrapper">
      <assignment-preview v-for="previewJob in currentJobs"
                          :key="previewJob.id"
                          :is-highlighted="assignment.id === previewJob.id"
                          :assignment="previewJob" />
    </div>
  </section>
</template>

<script>
  import AvailabilityTimeline from '@/components/shared_components/availability/AvailabilityTimeline';
  import AssignmentPreview from '@/components/assignment_components/invitation/AssignmentPreview';

  export default {
    components: {
      'availability-timeline': AvailabilityTimeline,
      'assignment-preview': AssignmentPreview
    },
    props: {
      assignment: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        isAssignmentPreviewOpened: false
      };
    },
    computed: {
      assignmentStartTime() { return this.$moment(this.assignment.startTime).format('YYYY-MM-DD HH:mm'); },
      assignmentFinishTime() { return this.$moment(this.assignment.finishTime).format('YYYY-MM-DD HH:mm'); },
      otherJobs() { return this.assignment.otherJobs || []; },
      currentJobs() {
        const otherJobs = [];
        const currentJob = this.assignment;
        if (this.otherJobs && this.otherJobs.length) {
          const previousJob = this.otherJobs.filter((job) => job.startTime < currentJob.startTime).sort((a, b) => b - a)[0];
          const nextJob = this.otherJobs.filter((job) => job.startTime > currentJob.startTime).sort((a, b) => a - b)[0];
          if (previousJob) otherJobs.push(previousJob);
          otherJobs.push(currentJob);
          if (nextJob) otherJobs.push(nextJob);
          return otherJobs;
        } else {
          return [];
        }
      },
      selectedEventData() {
        return this.assignment ? [this.assignment, 'job'] : null;
      },
      timelineEvents() {
        return [
          [this.assignment.otherJobs || [], 'job']
        ];
      },
    },
    methods: {
      toggleAssignmentPreview() {
        this.isAssignmentPreviewOpened = !this.isAssignmentPreviewOpened;
      }
    }
  };
</script>
<style scoped>
.assignment-timeline__wrapper {
  margin-bottom: 20px;
}
</style>

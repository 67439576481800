// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_types/phone_icon_black.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_types/video_icon_black.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/assignment_types/location_icon_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.assignment-preview[data-v-67ec1ec6] {\n  width: 100%;\n  margin: 8px 0;\n  padding: 12px 12px 12px 16px;\n  border-radius: 8px;\n  background-color: var(--cool-gray-50);\n}\n.assignment-preview.highlighted[data-v-67ec1ec6] {\n  background-color: #fff4f0;\n  outline: 2px solid var(--color-primary);\n}\n.assignment-preview__info-wrapper[data-v-67ec1ec6] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  line-height: 20px;\n}\n.assignment-preview__title[data-v-67ec1ec6] {\n  font-weight: 600;\n  font-size: 16px;\n}\n.assignment-preview__duration[data-v-67ec1ec6] {\n  color: var(--black-500);\n}\n.assignment-preview__session-type[data-v-67ec1ec6] {\n  margin-top: 8px;\n  padding-left: 20px;\n  background-position: left;\n  background-repeat: no-repeat;\n}\n.assignment-preview__session-type--phone[data-v-67ec1ec6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.assignment-preview__session-type--video[data-v-67ec1ec6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.assignment-preview__session-type--in_person[data-v-67ec1ec6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n", ""]);
// Exports
module.exports = exports;

import {mapActions, mapState} from 'vuex';

// This mixin requires the following on usage
// stopProgress, startProgress progress methods
// interpretation job object named assignment
export default {
  computed: {
    ...mapState('s_DashboardStore', {
      feedbackJobs: (state) => state.feedbackJobs || []
    }),
    ...mapState('EditProfileStore', ['profileData']),
    isPoliceJob() { return this.assignment.isPolice; }
  },
  methods: {
    ...mapActions('OneAssignmentStore', [
      'makeAbandon'
    ]),
    makeApply() {
      const params = {
        job_id: this.assignment.id,
      };
      this.startProgress();
      this.$store.dispatch('OneAssignmentStore/makeApply', {id: this.assignment.discussionId, params}).then(() => {
        this.stopProgress();
        this.showToast({message: this.$gettext('Invitation accepted'), type: 'success'});
      }).catch((error) => {
        if (error?.data?.errors?.job_entity_id?.includes('Jobs without feedback')) {
          this.$store.dispatch('s_DashboardStore/getFeedbackJobs').then(() => {
            this.stopProgress();
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.openFeedbackJobsModal();
            });
          });
          return;
        }
        this.stopProgress();
        this.$store.dispatch('ModalStore/closeModal');
        if (error?.data?.errors?.suitable_assignment
          && error.data.errors.suitable_assignment.length
          && error.data.errors.suitable_assignment.includes('Assignment is not suitable anymore')) {
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'unsuitable-job-modal',
              classes: ['unsuitable-modal'],
              data: {
                title: this.$gettext('This assignment is no longer available')
              },
              disableClose: true,
              width: 410
            });
          });
          return;
        }
        if (error?.data?.errors?.appliable
          && error.data.errors.appliable.length
          && error.data.errors.appliable.includes('Already applied')) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('You already applied to this invitation.'),
              method: 'modalCallback',
              context: this
            });
          });
          return;
        }
        if (error?.data?.errors?.job_id
          && error.data.errors.job_id.length) {
          if (error.data.errors.job_id.includes('Can\'t attend call without video attested')) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'video-test-apply-error-modal',
                data: {title: this.$gettext('To apply for this assignment you have to first pass the video test')}
              });
            }, 0);
            return;
          }
          if (error.data.errors.job_id.includes('police_confidential') || error.data.errors.job_id.includes('police_attested')) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'signatures-apply-error-modal',
                width: 410,
                classes: ['sk-modal--new'],
                data: {
                  title: this.$gettext('Signatures required'),
                  context: this,
                  notSignedDocs: error.data.errors.job_id
                }
              });
            }, 0);
            return;
          }
          if (error.data.errors.job_id.includes('Can\'t attend in-person job without address')) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'address-error-modal',
                width: 400,
                disableClose: true,
                data: {context: this}
              });
            }, 0);
            return;
          }
        }
        if (error?.data?.errors?.job_entity_id
          && error.data.errors.job_entity_id.length
          && error.data.errors.job_entity_id.includes('Default payout method required')) {
          this.$store.commit('ModalStore/setModal', {
            component: 'payout-info-modal',
            width: 600,
            data: {
              context: this,
              callback: 'openPriceTmpModal'
            }
          });
          return;
        }
        setTimeout(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        }, 0);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    },
    modalCallback() { window.location.reload(); },
    declineInvitation() {
      this.startProgress();
      this.makeAbandon(this.assignment.id).then(() => {
        this.stopProgress();
        this.showToast({message: this.$gettext('Invitation declined'), type: 'error'});
      }).catch((error) => {
        this.stopProgress();
        if (error.data
          && error.data.errors.length
          && error.data.errors.includes('Unallowed to abandon')) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('You have already declined this invitation.'),
              method: 'modalCallback',
              context: this
            });
          }, 0);
        }
      });
    },
    applyInvitation(params) {
      if (this.assignment.seriesSequenceNumber || this.assignment.sessionType === 'in_person') {
          return this.$router.push(this.singleAssignmentRoute);
      }
      if (this.assignment.dialect) {
        this.openDialectModal(params);
      } else {
        this.openJobApplyModal(params);
      }
    },
    openDialectModal(params) {
      this.$store.commit('ModalStore/setModal', {
        component: 'dialect-request-modal',
        width: 610,
        classes: ['sk-modal--new'],
        data: {
          context: this,
          title: this.$gettext('Special Dialect Request'),
          dialect: this.assignment.dialect,
          languageToId: this.assignment.langToId,
          params: params
        }
      });
    },
    openJobApplyModal(params) {
      if (this.feedbackJobs.length > 0) {
        this.openFeedbackJobsModal();
      } else if (this.isPoliceJob) {
        this.getUserProfileData().then(() => {
          if (!this.profileData.dataSharingConsentForPolice) {
            this.openPermissionModal(params);
            return;
          }
          this.openPriceTmpModal(params);
        });
      } else {
        this.openPriceTmpModal(params);
      }
    },
    openPriceTmpModal() {
      this.startProgress();
      this.$store.dispatch('AssignmentFlowStore/getInterModalInvoicePreviewData', {
        jobId: this.assignment.id
      }).then(() => {
        this.$store.commit('ModalStore/setModal', {
          component: 'apply-assign',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Confirm and send offer'),
            context: this
          }
        });
        this.stopProgress();
      }).catch((error) => {
        this.stopProgress();
        this.$store.commit('ModalStore/setModal', {
          component: 'error-modal',
          data: {
            error
          }
        });
      });
    },
    getUserProfileData() {
      return new Promise((resolve, reject) => {
        if (this.profileData) {
          resolve();
        } else {
          this.startProgress();
          this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']}).then(() => {
            this.stopProgress();
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }
      });
    },
    showToast({message, type}) {
      this.$store.commit('ToastStore/setToast', {
        data: {
          position: 'top-center',
          message: message,
          type
        }
      });
    }
  }
};

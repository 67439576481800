(<template>
  <article class="invitations-page is-progress-bar"
           :class="{'in-progress': progressActive}">
    <section class="invitations-page__navigation">
      <h3 class="invitations-page__title">{{ $gettext('Invitations') }}</h3>
      <secondary-select :items-list="sortFilters"
                        :description="selectDescription"
                        :filters="true"
                        :autofocus="true"
                        :secandary-value="true"
                        :preselected-value="sort"
                        class="invitations-page__filters"
                        @csvaluechanged="sortAssignments" />
    </section>
    <section class="invitations-page__assignments">
      <template v-if="availableAssignmentsPresent">
        <assignment-invitation v-for="assignment in availableAssignments"
                               :key="assignment.id"
                               :assignment="assignment" />
      </template>
      <empty-state v-else
                   :text="noAvailableAssignmentText" />
    </section>
  </article>
</template>)

<script>
  import AssignmentInvitation from '@/components/assignment_components/invitation/AssignmentInvitation';
  import {mapMutations, mapState} from 'vuex';
  import EmptyState from '@/components/dashboard_components/EmptyState';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      if (!store.state.s_DashboardStore.feedbackJobs) {
        promisesArr = [...promisesArr, store.dispatch('s_DashboardStore/getFeedbackJobs')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'assignment-invitation': AssignmentInvitation,
      'empty-state': EmptyState,
    },
    data() {
      return {
        progressActive: false,
        currentPage: this.$route.query.page || 1,
        sort: this.$route.query.sort || 'date'
      };
    },
    computed: {
      ...mapState('s_DashboardStore', {
        availableAssignments: (state) => state.availableAssignments?.data?.jobs || []
      }),
      selectDescription() { return this.$gettext('Sort by'); },
      sortFilters() {
        return [
          {id: 'date', name: this.$gettext('Date')},
          {id: 'duration', name: this.$gettext('Time')},
          {id: 'type', name: this.$gettext('Assignment type')}
        ];
      },
      availableAssignmentsPresent() { return this.availableAssignments && this.availableAssignments.length; },
      noAvailableAssignmentText() { return this.$gettext('You have no invited assignments right now.'); }
    },
    methods: {
      ...mapMutations('s_DashboardStore', [
        'removeSAvailableAssignments'
      ]),
      sortAssignments(value) {
        this.sort = value;
        this.getAvailableAssignments();
      },
      getAvailableAssignments() {
        const params = {};
        if (this.sort) params.sort = this.sort;
        this.progressActive = true;
        this.$store.dispatch('s_DashboardStore/getInvitedAssignmentDashboard', params).then(() => {
          this.progressActive = false;
          this.$store.commit('s_DashboardStore/setNewInvitationFlag', false);
        }).catch(() => {
          this.progressActive = false;
        });
      }
    },
    mounted() {
      // call can be SSE or REST so should be called on Browser
      if (!this.$store.state.s_DashboardStore.availableAssignments.data) this.getAvailableAssignments();
    },
    beforeDestroy() {
      this.removeSAvailableAssignments();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('s_DashboardStore/clearFeedbackJobs');
      next();
    }
  };
</script>

<style>
  .invitations-page__navigation .sk-date-picker {
    width: auto;
    border: none;
  }

  .invitations-page__navigation .sk-date-picker__value {
    width: auto;
    min-width: 100px;
    padding-right: 25px;
    background-color: transparent;
    font-weight: bold;
  }

  .invitations-page__navigation .sk-date-picker__value::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(43deg);
  }

  .invitations-page__navigation .sk-date-picker__value::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(-43deg);
  }

  .invitations-page__navigation .is-active .sk-date-picker__value::before {
    transform: rotate(-43deg);
  }

  .invitations-page__navigation .is-active .sk-date-picker__value::after {
    transform: rotate(43deg);
  }

  .invitations-page__navigation .sk-date-picker__value:active {
    width: auto;
    padding-right: 25px;
    background-color: transparent;
  }

  .invitations-page__navigation .sk-date-picker__calendar.sk-date-picker__calendar {
    left: -100px;
  }

  .invitations-page__navigation .sk-select {
    background-color: white;
  }

  @media (min-width: 768px) {
    .invitations-page__navigation .secandary-select__value-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .invitations-page__navigation .secandary-select__description {
      width: auto;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    .invitations-page__navigation .secandary-select__value {
      display: none;
    }
  }
</style>

<style scoped>
  .invitations-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100%;
    padding: 15px 15px 80px 15px;
    background-color: #f4f5f7;
  }

  .invitations-page__navigation,
  .invitations-page__assignments {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 950px;
    border-radius: 8px;
    background-color: #fff;
  }

  .invitations-page__navigation {
    align-items: center;
    justify-content: space-between;
    margin-bottom: -10px;
    padding: 16px 20px;
  }

  .invitations-page__title {
    font-weight: 400;
    font-size: 18px;
  }

  .invitations-page__filters {
    min-width: 135px;
    padding-top: 7px;
  }

  .invitations-page__assignments > *:not(:last-child) {
    border-bottom: 1px var(--cool-gray-200) solid;
  }

  @media (max-width: 767px) {
    .invitations-page {
      padding: 15px 0 0;
    }

    .invitations-page__filters {
      padding-top: 0;
    }
  }
</style>

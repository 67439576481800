<template>
  <article class="assignment-preview"
           :class="{'highlighted': isHighlighted}">
    <div class="assignment-preview__info-wrapper">
      <div class="assignment-preview__first-section">
        <p class="assignment-preview__title">{{ previewTitle }}</p>
        <p class="assignment-preview__duration">{{ duration }}</p>
        <p class="assignment-preview__session-type"
           :class="[`assignment-preview__session-type--${sessionType}`]">{{ sessionTypeValue }}</p>
      </div>
      <sk-chip :label="status.text"
               :color="status.class" />
    </div>
  </article>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      assignment: {
        type: Object,
        default: () => {}
      },
      isHighlighted: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      langTo() { return this.$getLangName(this.assignment.langToId); },
      category() { return this.assignment.category; },
      subject() { return this.assignment.subject; },
      previewTitle() {
        const title = this.subject || this.category;
        const subjectCategoryCombo = this.subject && this.category ? `${this.category}: ${this.subject}` : title;
        return `${subjectCategoryCombo} ${this.langTo}`;
      },
      duration() {
        const statTime = this.assignment.startTime;
        const finishTime = this.assignment.finishTime;
        return `${this.$moment(statTime).format('HH:mm')} - ${this.$moment(finishTime).format('HH:mm')}`;
      },
      status() { return helpers.getJobInfo.jobStatusObjChip(this, this.assignment.status); },
      sessionType() { return this.assignment.sessionType; },
      sessionTypeValue() {
        switch (this.sessionType) {
          case 'in_person':
            return helpers.getFullAddress(this.assignment.address);
          case 'video':
            return this.assignment.videoPlatformName;
          default:
            return helpers.getJobInfo.jobType(this.sessionType, this);
        }
      }
    }
  };
</script>
<style scoped>
.assignment-preview {
  width: 100%;
  margin: 8px 0;
  padding: 12px 12px 12px 16px;
  border-radius: 8px;
  background-color: var(--cool-gray-50);
}

.assignment-preview.highlighted {
  background-color: #fff4f0;
  outline: 2px solid var(--color-primary);
}

.assignment-preview__info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
}

.assignment-preview__title {
  font-weight: 600;
  font-size: 16px;
}

.assignment-preview__duration {
  color: var(--black-500);
}

.assignment-preview__session-type {
  margin-top: 8px;
  padding-left: 20px;
  background-position: left;
  background-repeat: no-repeat;
}

.assignment-preview__session-type--phone {
  background-image: url(~@assets/imgs/assignment_types/phone_icon_black.svg);
}

.assignment-preview__session-type--video {
  background-image: url(~@assets/imgs/assignment_types/video_icon_black.svg);
}

.assignment-preview__session-type--in_person {
  background-image: url(~@assets/imgs/assignment_types/location_icon_black.svg);
}
</style>

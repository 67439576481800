(<template>
  <div class="empty-state"
       :class="{'empty-state--list': listClass}">
    <span v-html="text"></span>
  </div>
</template>)

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ''
      },
      listClass: {
        type: Boolean
      }
    }
  };
</script>

<style scoped>
  .empty-state {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    min-height: 72px;
    color: #7a829d;
    font-size: 18px;
    text-align: center;
  }

  .empty-state span {
    max-width: 100%;
  }

  .empty-state--list {
    margin: 20px 0;
    text-align: left;
  }
</style>
